const { default: server } = require("./server");

export const getAppSettings = async () => {
  const response = await server().get("/settings/siteInfo");
  return response.data.data.siteInfo;
};

export const editAppSettings = async (data) => {
  const response = await server().put("/settings/siteInfo", data);
  return response.data.data.siteInfo;
};
