import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import { getPageSection } from "api/pages";
import FeaturesArray from "./FeaturesArray";
const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [features, setFeatures] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onStart = async () => {
    const pageData = await getPageSection("home", slug);
    setData(pageData);
    setFeatures(pageData.features);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      description: data?.description,
      features: data?.features,
    });
  }, [reset, data]);

  const onSubmit = async (data) => {
    data.features = features;

    // eslint-disable-next-line no-unused-vars
    const savedResponse = await server().put(`/pages/home/sections/${slug}`, {
      section: data,
    });
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>مميزات الموقع</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} lg={12} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان رئيسي"
                          {...register("title", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.type === "required" &&
                          "يرجي ادخال  عنوان رئيسي"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="وصف رئيسي"
                          {...register("description", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.description?.type === "required" &&
                          "يرجي ادخال وصف رئيسي"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <FeaturesArray
                      features={features}
                      setFeatures={setFeatures}
                    />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
