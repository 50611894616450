import React from "react";
import removeImg from "../../../assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";

const ProvidersArray = ({ features, setFeatures }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newFeaturesArray = features.map((feature) => {
      if (feature.id !== id) return feature;
      return { ...feature, [name]: value };
    });

    setFeatures(newFeaturesArray);
  };

  const removeFeature = (featureId) => {
    const newFeatures = features.filter(
      (feature, index) => feature.id !== featureId
    );
    setFeatures(() => newFeatures);
  };

  const addFeature = () => {
    setFeatures(() => [
      ...features,
      { id: v4(), title: "", description: "", path: "" },
    ]);
  };

  return (
    <div className="form-group branches features">
      <h5>
        مميزات مقدمي الخدمات
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            addFeature();
          }}
        >
          إضافة ميزة
        </button>
      </h5>
      <Row>
        {features.map((feature, index) => {
          return (
            <Col xxl={4} lg={4} md={6} xs={12} key={index}>
              <div key={feature.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => removeFeature(feature.id)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12}>
                    <ImageUpdate
                      item={feature}
                      features={features}
                      setFeatures={setFeatures}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>عنوان العنصر</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="عنوان العنصر"
                        name="title"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.title}
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>وصف العنصر</h5>
                      <textarea
                        className="form-control form-outline"
                        placeholder="وصف العنصر"
                        name="description"
                        onChange={(e) => handleInputOnChange(e, feature.id)}
                        value={feature?.description}
                      ></textarea>
                      <input type="hidden" value={v4()} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ProvidersArray;
