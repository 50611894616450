const { default: server } = require("./server");

export const getPageData = async (pageSlug) => {
  const response = await server().get(`/pages/${pageSlug}`);
  return response.data.data.page;
};

export const getPageSection = async (pageSlug, sectionSlug) => {
  const response = await server().get(
    `/pages/${pageSlug}/sections/${sectionSlug}`
  );
  return response.data.data.section;
};
