import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { editAdmin, getAdmin } from "api/admins";

const EditAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState();

  const { register, handleSubmit, reset } = useForm();

  const onStart = async () => {
    const pageData = await getAdmin(id);
    setData(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      name: data?.name,
      email: data?.email,
      passowrd: data?.password,
    });
  }, [data, reset]);
  const onSubmit = (data) => {
    // eslint-disable-next-line no-unused-vars
    const response = editAdmin(id, data);
    navigate("/admins");
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المشرف</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المشرف"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>البريد الالكتروني</h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder="البريد الالكتروني"
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>كلمة المرور</h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder="كلمة المرور"
                          {...register("password", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
