const { default: server } = require("./server");

export const getAllSocials = async () => {
  const response = await server().get("/settings/socials");
  return response.data.data.socials;
};

export const getSocial = async (id) => {
  const response = await server().get(`/settings/socials/${id}`);
  return response.data.data;
};

export const addSocial = async (data) => {
  const response = await server().post("/settings/socials", data);
  return response.data.data.socials;
};

export const editSocial = async (id, data) => {
  const response = await server().put(`/settings/socials/${id}`, data);
  return response.data.data.social;
};

export const deleteSocial = async (id) => {
  const response = await server().delete(`/settings/socials/${id}`);
  return response.data;
};
