import { getAllSocialTypes } from "api/socialTypes";
import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import Table from "./Table";
import shareIcon from "assets/svgs/share.svg";
const Index = () => {
  const [socialTypes, setSocialTypes] = useState([]);

  const onStart = async () => {
    const pageData = await getAllSocialTypes();
    setSocialTypes(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="اضافة نوع جديد"
          cardTxt="أنواع وسائل التواصل الاجتماعي"
          icon={shareIcon}
          url="/types/add"
        />
        <Table data={socialTypes} setSocialTypes={setSocialTypes} />
      </div>
    </>
  );
};

export default Index;
