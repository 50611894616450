import React, { useEffect } from "react";
import server from "api/server";

const MultiUpload = ({ imagesSrc, images, setImages }) => {
  useEffect(() => {
    let modifiedImages = [];
    imagesSrc?.map((image) => {
      return modifiedImages.push({
        path: image,
      });
    });
    setImages(modifiedImages);
  }, [imagesSrc, setImages]);

  const onImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);
        const { data } = await server().post("/upload", formData);
        setImages((oldImages) => [
          ...oldImages,
          {
            preview: e.target.files[i],
            path: data.data.file.path,
          },
        ]);
      }
    }
  };

  const removeImages = (image) => {
    setImages(images.filter((img) => img !== image));
  };

  return (
    <div className="form-group required">
      <h5>شاشات من داخل التطبيق</h5>
      <div>
        <label className="form-uploader">
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={onImageChange}
            id="filo"
            name="images"
          />
          <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 16.0001H6.00004C2.87441 16.002 0.270931 13.604 0.0164008 10.4887C-0.238129 7.37347 1.94156 4.58465 5.02604 4.07911C6.44575 1.562 9.11015 0.00362534 12 5.65992e-05C13.8021 -0.00675485 15.5525 0.601415 16.962 1.72411C18.3461 2.82197 19.3301 4.34509 19.762 6.05811C22.3459 6.45514 24.1878 8.77563 23.9881 11.3822C23.7883 13.9888 21.6143 16.0015 19 16.0001ZM12 2.0001C9.83175 2.00267 7.83271 3.17221 6.76804 5.06111L6.30004 5.90011L5.35104 6.05511C3.30132 6.39852 1.85605 8.25441 2.02525 10.3258C2.19446 12.3972 3.92176 13.9939 6.00004 14.0001H19C20.5686 14.0017 21.8736 12.7947 21.9942 11.2308C22.1149 9.66685 21.0103 8.27401 19.46 8.03511L18.144 7.83511L17.822 6.54311C17.1573 3.86992 14.7546 1.99507 12 2.0001ZM13.45 12.0001H10.55V9.00011H8.00004L12 5.00011L16 9.00011H13.45V12.0001Z"
              fill="#2E3A59"
            />
          </svg>
          <span className="txt">اضافة صور جديدة</span>
        </label>
        <div className="thumbs">
          {images.map((imageSrc, index) => (
            <div key={index} className="thumb">
              <button
                type="button"
                className="btn-remove"
                onClick={() => removeImages(imageSrc)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                    fill="#fff"
                  />
                </svg>
              </button>
              <img
                src={
                  imageSrc.preview
                    ? URL.createObjectURL(imageSrc.preview)
                    : imageSrc
                }
                width={100}
                height={100}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiUpload;
