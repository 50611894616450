import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import Table from "./Table";
import adminsIcon from "assets/svgs/customerIcon.svg";
import { getAllAdmins } from "api/admins";
const Index = () => {
  const [admins, setAdmins] = useState([]);

  const onStart = async () => {
    const pageData = await getAllAdmins();
    setAdmins(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="اضافة مشرف"
          cardTxt="المشرفين"
          icon={adminsIcon}
          url="/admins/add"
        />
        <Table data={admins} setAdmins={setAdmins} />
      </div>
    </>
  );
};

export default Index;
