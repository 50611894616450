import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { addSocial } from "api/socials";
import { getAllSocialTypes } from "api/socialTypes";
import { useIntl } from "react-intl";
const AddSocial = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  const [socialTypes, setSocialTypes] = useState([]);

  const onStart = async () => {
    const pageData = await getAllSocialTypes();
    setSocialTypes(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // eslint-disable-next-line no-unused-vars
    const response = addSocial(data);
    navigate("/settings/socials");
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضافة وسيلة تواصل جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>نوع وسيلة التواصل</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("socialType", { required: true })}
                        >
                          <option>يرجي اختيار وسيلة التواصل</option>
                          {socialTypes?.map((type) => (
                            <option key={type?.id} value={type?.id}>
                              {type?.name?.[locale]}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors?.socialType?.type === "required" &&
                          "يرجي ادخال نوع الوسيلة"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوسيلة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوسيلة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"].type === "required" &&
                          "يرجي ادخال اسم الوسيلة بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوسيلة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوسيلة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"].type === "required" &&
                          "يرجي ادخال اسم الوسيلة بالانجليزية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>رابط الوسيلة</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رابط الوسيلة"
                          {...register("link", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.link?.type === "required" &&
                          "يرجي ادخال رابط الوسيلة"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSocial;
