const { default: server } = require("./server");

export const getContactUsOrders = async () => {
  const response = await server().get("/contactUs");
  return response.data.data.contacts;
};

export const getContactUsOrder = async (id) => {
  const response = await server().get(`/contactUs/${id}`);
  return response.data.data.category;
};

export const deleteContactUsOrder = async (id) => {
  const response = await server().delete(`/contactUs/${id}`);

  return response.data;
};
