import React from "react";
import removeImg from "../../../assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";

const ReviewsArray = ({ clients, setClients }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newClientsArray = clients.map((client) => {
      if (client.id !== id) return client;
      return { ...client, [name]: value };
    });

    setClients(newClientsArray);
  };

  const removeClient = (clientId) => {
    const newClients = clients.filter(
      (client, index) => client.id !== clientId
    );
    setClients(() => newClients);
  };

  const addClient = () => {
    setClients(() => [
      ...clients,
      { id: v4(), rate: "", description: "", name: "", path: "", jobTitle: "" },
    ]);
  };

  return (
    <div className="form-group branches features">
      <h5>
        التقييمات
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            addClient();
          }}
        >
          إضافة تقييم
        </button>
      </h5>
      <Row>
        {clients.map((client, index) => {
          return (
            <Col xxl={4} lg={6} md={6} xs={12} key={index}>
              <div key={client.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => removeClient(client.id)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>التقييم</h5>
                      <input
                        type="number"
                        max={5}
                        maxLength={1}
                        className="form-control form-outline"
                        placeholder="التقييم"
                        name="rate"
                        onChange={(e) => handleInputOnChange(e, client.id)}
                        value={client?.rate}
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>وصف العميل</h5>
                      <textarea
                        className="form-control form-outline"
                        placeholder="وصف العميل"
                        name="description"
                        onChange={(e) => handleInputOnChange(e, client.id)}
                        value={client?.description}
                      ></textarea>
                    </div>
                  </Col>
                  <input type="hidden" value={v4()} />
                  <Col lg={12}>
                    <ImageUpdate
                      item={client}
                      clients={clients}
                      setClients={setClients}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>اسم العميل</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم العميل"
                        name="name"
                        onChange={(e) => handleInputOnChange(e, client.id)}
                        value={client?.name}
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>الوظيفة</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="الوظيفة"
                        name="jobTitle"
                        onChange={(e) => handleInputOnChange(e, client.id)}
                        value={client?.jobTitle}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ReviewsArray;
