import React, { useEffect, useState } from "react";
import { getSubscribers } from "api/subscribers";
import Table from "./Table";
const Index = () => {
  const [subscribers, setSubscribers] = useState([]);

  const onStart = async () => {
    const pageData = await getSubscribers();
    setSubscribers(pageData);
  };

  // render methods
  // const getTableData = async () => {
  //   try {
  //     const subs = await getSubscribers();
  //     setSubscribers(subs);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="users-wrap">
        {/* <Table data={subscribers} getTableData={getTableData} /> */}
        <Table data={subscribers} setSubscribers={setSubscribers} />
      </div>
    </>
  );
};

export default Index;
