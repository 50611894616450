import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";

const rootReducer = combineReducers({
  authentication,
});

export default rootReducer;
