const { default: server } = require("./server");

export const getAllSocialTypes = async () => {
  const response = await server().get("/settings/socialTypes");
  return response.data.data.socialTypes;
};

export const getSocialType = async (id) => {
  const response = await server().get(`/settings/socialTypes/${id}`);
  return response.data.data.socialType;
};

export const addSocialType = async (data) => {
  const response = await server().post("/settings/socialTypes", data);
  return response.data.data.socialTypes;
};

export const editSocialType = async (id) => {
  const response = await server().put(`/settings/socialTypes/${id}`);
  return response.data.data.socialTypes;
};

export const deleteSocialType = async (id) => {
  const response = await server().delete(`/settings/socialTypes/${id}`);
  return response.data;
};
