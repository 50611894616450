const { default: server } = require("./server");

export const getAllAdmins = async () => {
  const response = await server().get("/users");
  return response.data.data.users;
};

export const getAdmin = async (id) => {
  const response = await server().get(`/users/${id}`);
  return response.data.data.user;
};

export const addAdmin = async (data) => {
  const response = await server().post("/users", data);
  return response.data.token;
};

export const editAdmin = async (id, data) => {
  const response = await server().put(`/users/${id}`, data);
  return response.data.data.user;
};

export const deleteAdmin = async (id) => {
  const response = await server().delete(`/users/${id}`);
  return response.data;
};
