import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";

import { useLocation } from "react-router-dom";
import { getPageSection } from "api/pages";
import { EditorComponent } from "components/shared/FormComponents";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/")[2];
  const [description, setDescription] = useState("");
  const [data, setData] = useState([]);

  const { handleSubmit, control } = useForm();

  const onStart = async () => {
    const pageData = await getPageSection("data-deletion", slug);
    setDescription(pageData?.description);

    setData(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    data.description = description;

    // eslint-disable-next-line no-unused-vars
    const savedResponse = await server().put(
      `/pages/data-deletion/sections/${slug}`,
      {
        section: data,
      }
    );
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>حذف البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>محتوى الصفحة</h5>

                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="description"
                            setData={setDescription}
                            initialValue={data?.description}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
