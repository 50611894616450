import { getContactUsOrder } from "api/contactUs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Show = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  const onStart = async () => {
    const pageData = await getContactUsOrder(id);
    setData(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contact-wrap">
      <div className="card">
        <div className="card-head">
          <h4 className="card-title">تفاصيل الطلب</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <strong>الاسم</strong>
                  </td>
                  <td>{data?.firstName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>البريد الالكتروني</strong>
                  </td>
                  <td>{data?.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>اسم الشركة</strong>
                  </td>
                  <td>{data?.componyName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>رقم الهاتف</strong>
                  </td>
                  <td>{data?.phone}</td>
                </tr>
                <tr>
                  <td>
                    <strong>الرسالة</strong>
                  </td>
                  <td>{data?.message}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
