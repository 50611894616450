import React from "react";
import removeImg from "../../../assets/svgs/close.svg";
import { v4 } from "uuid";
import { Col, Row } from "react-bootstrap";

const StatisticsArray = ({ fields, append, remove, register, errors }) => {
  return (
    <div className="form-group branches features">
      <h5>
        الاحصائيات
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            append({});
          }}
        >
          إضافة احصائية
        </button>
      </h5>
      <Row>
        {fields.map((item, index) => {
          return (
            <Col xl={4} lg={6} md={6} xs={12} key={index}>
              <div key={item.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12} xs={12}>
                    <input
                      type="hidden"
                      value={v4()}
                      {...register(`stats.${index}.id`, {
                        required: true,
                      })}
                    />
                    <div className="form-group">
                      <h5>رقم الاحصائية</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="رقم الاحصائية"
                        {...register(`stats.${index}.number`, {
                          required: true,
                        })}
                      />
                      <p className="error-hint">
                        {errors.stats?.["number"]?.type === "required" &&
                          "يرجي ادخال  رقم الاحصائية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>عنوان الاحصائية</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="عنوان الاحصائية"
                        {...register(`stats.${index}.title`, {
                          required: true,
                        })}
                      />
                      <p className="error-hint">
                        {errors.stats?.["title"]?.type === "required" &&
                          "يرجي ادخال  عنوان الاحصائية"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default StatisticsArray;
