import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import { editAppSettings, getAppSettings } from "api/settings";

const Index = () => {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onStart = async () => {
    const pageData = await getAppSettings();
    setData(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.file.path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = async (data) => {
    if (selectedImage && selectedImage.path)
      data["appLogo"] = selectedImage.path;
    data.appLogo = `${process.env.REACT_APP_URL}/${
      "uploads/" + data.appLogo.split("/").pop()
    }`;

    // eslint-disable-next-line no-unused-vars
    const savedResponse = editAppSettings(data);
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>بيانات التطبيق</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>شعار التطبيق</h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage && selectedImage.preview
                                    ? URL.createObjectURL(selectedImage.preview)
                                    : data?.appLogo
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.image?.type === "required" &&
                            "يرجي اختيار صورة للنبذة"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التطبيق بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التطبيق بالعربية"
                          {...register("appName[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.appName?.["ar"].type === "required" &&
                          "يرجي ادخال اسم التطبيق بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التطبيق بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التطبيق بالانجليزية"
                          {...register("appName[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.appName?.["en"].type === "required" &&
                          "يرجي ادخال اسم التطبيق بالانجليزية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف التطبيق بالعربية</h5>
                      <div>
                        <textarea
                          className="form-control form-outline"
                          placeholder="وصف التطبيق بالعربية"
                          {...register("appDesc[ar]", { required: true })}
                        ></textarea>
                      </div>
                      <p className="error-hint">
                        {errors?.appDesc?.["ar"].type === "required" &&
                          "يرجي ادخال وصف التطبيق بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف التطبيق بالانجليزية</h5>
                      <div>
                        <textarea
                          className="form-control form-outline"
                          placeholder="وصف التطبيق بالانجليزية"
                          {...register("appDesc[en]", { required: true })}
                        ></textarea>
                      </div>
                      <p className="error-hint">
                        {errors?.appDesc?.["en"].type === "required" &&
                          "يرجي ادخال وصف التطبيق بالانجليزية"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group required">
                      <h5>لينك جوجل بلاي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="لينك جوجل بلاي"
                          dir="ltr"
                          {...register("playStoreLink", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.playStoreLink?.type === "required" &&
                          "يرجي ادخال الحقل"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group required">
                      <h5>لينك ابل ستور</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="لينك ابل ستور"
                          dir="ltr"
                          {...register("appStoreLink", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.appStoreLink?.type === "required" &&
                          "يرجي ادخال الحقل"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>حقوق التطبيق بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="حقوق التطبيق بالعربية"
                          {...register("copyright[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.copyright?.["ar"].type === "required" &&
                          "يرجي ادخال حقوق التطبيق بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>حقوق التطبيق بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="حقوق التطبيق بالانجليزية"
                          {...register("copyright[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.copyright?.["en"].type === "required" &&
                          "يرجي ادخال حقوق التطبيق بالانجليزية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
