import { getContactUsOrders } from "api/contactUs";
import React, { useEffect, useState } from "react";
import Table from "./Table";
const Index = () => {
  const [orders, setOrders] = useState([]);

  const onStart = async () => {
    const pageData = await getContactUsOrders();
    setOrders(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="users-wrap">
        <Table data={orders} setOrders={setOrders} />
      </div>
    </>
  );
};

export default Index;
