import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import { getPageSection } from "api/pages";
import imageUpload from "../../../assets/svgs/imageUpload.svg";
import OwnersArray from "./OwnersArray";
const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/")[2];
  const [data, setData] = useState([]);
  const [ownersImg, setOwnersImg] = useState({
    preview: null,
    path: null,
  });
  const [features, setFeatures] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onStart = async () => {
    const pageData = await getPageSection("home", slug);
    setData(pageData);
    setFeatures(pageData.features);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      description: data?.description,
      image: data?.image,
      features: data?.features,
    });
  }, [reset, data]);

  const ownersImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const myFormData = new FormData();
      myFormData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", myFormData);
      setOwnersImg({
        preview: e.target.files[0],
        path: data.data.file.path,
      });
    }
  };

  const removeOwnersImg = () => {
    setOwnersImg();
  };

  const onSubmit = async (data) => {
    if (ownersImg && ownersImg.path) data["image"] = ownersImg.path;
    data.image = `${process.env.REACT_APP_URL}/${data?.image}`;
    data.features = features;
    // eslint-disable-next-line no-unused-vars
    const savedResponse = await server().put(`/pages/home/sections/${slug}`, {
      section: data,
    });
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>أصحاب الأعمال</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة مقدمي الخدمات</h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {ownersImg && (
                            <div>
                              <img
                                src={
                                  ownersImg && ownersImg.preview
                                    ? URL.createObjectURL(ownersImg.preview)
                                    : data.image
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeOwnersImg}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={ownersImageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.image?.type === "required" &&
                            "يرجي اختيار صورة أصحاب الأعمال"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان رئيسي"
                          {...register("title", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.type === "required" &&
                          "يرجي ادخال  عنوان رئيسي"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>وصف رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="وصف رئيسي"
                          {...register("description", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.description?.type === "required" &&
                          "يرجي ادخال وصف رئيسي"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <OwnersArray
                      features={features}
                      setFeatures={setFeatures}
                    />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
