const { default: server } = require("./server");

export const getSubscribers = async () => {
  const response = await server().get("/subscribers");
  return response.data.data.subscribers;
};

export const deleteSubscribe = async (id) => {
  const response = await server().delete(`/subscribers/${id}`);

  return response.data;
};
