import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import Table from "./Table";
import shareIcon from "assets/svgs/share.svg";
import { getAllSocials } from "api/socials";
const Index = () => {
  const [socials, setSocials] = useState([]);

  const onStart = async () => {
    const pageData = await getAllSocials();
    setSocials(pageData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="اضافة وسيلة تواصل جديدة"
          cardTxt="وسائل التواصل الاجتماعي"
          icon={shareIcon}
          url="/socials/add"
        />
        <Table data={socials} setSocials={setSocials} />
      </div>
    </>
  );
};

export default Index;
